$(function() {
	console.log('started');

	var home_hero_swiper = new Swiper('.home-hero .swiper-container', {
		navigation: {
			nextEl: '.home-hero .swiper-container .swiper-navigation .swiper-arrow-down',
			prevEl: '.home-hero .swiper-container .swiper-navigation .swiper-arrow-up'
		},
		pagination: {
			clickable: true,
			el: '.home-hero .swiper-container .swiper-pagination',
			type: 'bullets'
		},
		simulateTouch: false
	});

	var product_category_swiper;

	function product_cats() {
		if ($('.marker').css('display') != 'none') {
			product_category_swiper = new Swiper('.product-box .product-categories', {
				loop: true,
				on: {
					init: function() {
						let $el = $(this.hostEl);
						let cat = $el.find('.swiper-wrapper .swiper-slide').eq(this.activeIndex).attr('data-name');
						$('.product-examples').attr('data-section', cat);
						let $idx = $('.product-examples-slider .swiper-wrapper .swiper-slide[data-name="'+ cat +'"]').index();
						product_examples_swiper.slideTo($idx);
					},
					slideChangeTransitionEnd: function() {
						let $el = $(this.hostEl);
						let cat = $el.find('.swiper-wrapper .swiper-slide').eq(this.activeIndex).attr('data-name');
						$('.product-examples').attr('data-section', cat);
						let $idx = $('.product-examples-slider .swiper-wrapper .swiper-slide[data-name="'+ cat +'"]').index();
						product_examples_swiper.slideTo($idx);
					}
				},
				simulateTouch: false,
				slidesPerView: 'auto',
				spaceBetween: 20
			});
		}
		else {
			if (product_category_swiper) {
				product_category_swiper.destroy(true, true);
			}

			let cat = $('.product-categories .swiper-slide').first().attr('data-name');
			$('.product-examples').attr('data-section', cat);
			$('.product-categories .swiper-slide').each(function() {
				$(this).removeClass('swiper-slide-active')
			});
			$('.product-categories .swiper-slide[data-name="'+ cat +'"').addClass('swiper-slide-active');
		}
	}

	$('.product-categories .swiper-slide').on('click', function(e) {
		e.preventDefault();
		let name = $(this).attr('data-name');
		let $el = $('.product-examples-slider .swiper-wrapper .swiper-slide[data-name="'+ name +'"]').index();
		if ($('.marker').css('display') == 'none') {
			product_examples_swiper.slideTo($el);
			$('.product-categories .swiper-slide').each(function() {
				$(this).removeClass('swiper-slide-active')
			});
			$('.product-categories .swiper-slide[data-name="'+ name +'"').addClass('swiper-slide-active');
			$('.product-examples').attr('data-section', name);
		}
	});

	product_cats();
	$(window).on('resize', function() {
		product_cats();
	});

	var product_examples_swiper = new Swiper('.product-box .product-examples .product-examples-slider', {
		allowTouchMove: false,
		simulateTouch: false
	});

	$('section.mask .mask-over').Scrollio({
		action: 'parallax',
		parallax: {
			property: 'background-size',
			begin: 100,
			end: 5000,
			template: '{value} auto',
			unit: '%',
			duration: 100
		},
		trigger: {
			multiplier: 1
		}
	});

	$('.section.mask .mask-wrapper').on('ScrollioMatch', function() { console.log('match'); })
		.on('ScrollioUnmatch', function() { console.log('unmatch'); })
		.Scrollio({
			action: 'toggleClass',
			class: 'fade',
			trigger: {
				multiplier: .5
			}
		});
});