$(function() {
	console.log('started');

	$('.has-sub .caret').on('click', function(e) {
		e.preventDefault();

		let $parent = $(this).parent();

		if ($parent.attr('data-state') == 'closed') {
			$parent.attr('data-state', 'open');
			let $tgt = $parent.parent().find('.sub-menu-wrapper'),
				ht = $tgt.find('.sub-menu').height();
			$tgt.css({'max-height': ht});
		}
		else {
			$parent.attr('data-state', 'closed');
			let $tgt = $parent.parent().find('.sub-menu-wrapper');
			$tgt.css({'max-height': 0});
		}
	});

	var home_hero_swiper = new Swiper('.home-hero .swiper-container', {
		navigation: {
			nextEl: '.home-hero .swiper-container .swiper-navigation .swiper-arrow-down',
			prevEl: '.home-hero .swiper-container .swiper-navigation .swiper-arrow-up'
		},
		on: {
			slideChangeTransitionEnd: function() {
				$('.section.hero').addClass('animated');
			}
		},
		pagination: {
			clickable: true,
			el: '.home-hero .swiper-container .swiper-pagination',
			type: 'bullets'
		},
		simulateTouch: false
	});

	var product_examples_swiper = new Swiper('.product-box .product-examples .product-examples-slider', {
		allowTouchMove: false,
		simulateTouch: false
	});

	var product_category_swiper;

	function product_cats() {
		if ($('.marker').css('display') != 'none') {
			product_category_swiper = new Swiper('.product-box .product-categories', {
				loop: true,
				on: {
					init: function() {
						let $el = $(this.hostEl);
						let cat = $el.find('.swiper-wrapper .swiper-slide').eq(this.activeIndex).attr('data-name');
						$('.product-examples').attr('data-section', cat);
						let $idx = $('.product-examples-slider .swiper-wrapper .swiper-slide[data-name="'+ cat +'"]').index();
						product_examples_swiper.slideTo($idx);
					},
					slideChangeTransitionEnd: function() {
						let $el = $(this.hostEl);
						let cat = $el.find('.swiper-wrapper .swiper-slide').eq(this.activeIndex).attr('data-name');
						$('.product-examples').attr('data-section', cat);
						let $idx = $('.product-examples-slider .swiper-wrapper .swiper-slide[data-name="'+ cat +'"]').index();
						product_examples_swiper.slideTo($idx);
					}
				},
				simulateTouch: false,
				slidesPerView: 'auto',
				spaceBetween: 20
			});
		}
		else {
			if (product_category_swiper) {
				product_category_swiper.destroy(true, true);
			}

			let cat = $('.product-categories .swiper-slide').first().attr('data-name');
			$('.product-examples').attr('data-section', cat);
			$('.product-categories .swiper-slide').each(function() {
				$(this).removeClass('swiper-slide-active')
			});
			$('.product-categories .swiper-slide[data-name="'+ cat +'"').addClass('swiper-slide-active');
		}
	}

	$('.product-categories .swiper-slide').on('click', function(e) {
		e.preventDefault();
		let name = $(this).attr('data-name');
		let $el = $('.product-examples-slider .swiper-wrapper .swiper-slide[data-name="'+ name +'"]').index();
		if ($('.marker').css('display') == 'none') {
			product_examples_swiper.slideTo($el);
			$('.product-categories .swiper-slide').each(function() {
				$(this).removeClass('swiper-slide-active')
			});
			$('.product-categories .swiper-slide[data-name="'+ name +'"').addClass('swiper-slide-active');
			$('.product-examples').attr('data-section', name);
		}
	});

	product_cats();
	$(window).on('resize', function() {
		product_cats();
	});

	$('.blade.sostenibilita').Scrollio({
		action: 'toggleClass',
		class: 'in',
		trigger: {
			multiplier: .5
		}
	});

	$('section.mask .pre-mask').on('ScrollioMatch', function() { $('section.mask .mask-over').addClass('mask-end'); })
	.on('ScrollioUnmatch', function() { console.log('unmatch'); })
	.Scrollio({
		action: 'setClass',
		class: 'in',
		trigger: {
			multiplier: .75
		}
	});

	$('section.mask .mask-over').Scrollio({
		action: 'parallax',
		parallax: {
			property: 'background-size',
			begin: 100,
			end: 5000,
			template: '{value} auto',
			unit: '%',
			duration: 100
		},
		trigger: {
			multiplier: 1
		}
	});
});